import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faInstagram } from '@fortawesome/free-brands-svg-icons';
import {handleRecruitment}  from "../utilities/methods";
import hwlogo from '../images/HWlogo.png'; 

export const Footer = () => {
  return (
    <div className="xl:h-[10rem] w-full text-[#e87d0e] mt-5">
      <div className="w-full h-full xl:max-w-[1250px] mx-auto px-4 flex flex-col xl:flex-row items-center">
        <div className="flex-1 flex flex-col items-center">
          <p className="text-[1.5rem] font-medium text-center text-shadow-md">Start your Journey Today!</p>
          <div className="flex justify-center items-center space-x-8 mt-6">
            <a href="/" className="text-[1.5rem] font-medium text-center text-shadow-md">
              <img src={hwlogo} alt="logo" className="h-12 rounded-full" />
            </a>
            <a href="https://www.instagram.com/housewolfsc/" className="text-[1rem] font-medium text-center text-shadow-md">
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
            <a href="https://discord.gg/nb7hm7mFae" className="text-[1rem] font-medium text-center text-shadow-md">
              <FontAwesomeIcon icon={faDiscord} size="2x" />
            </a>
          </div>
          <button className="px-5 bg-gradient-to-tr from-[#470000] to-black rounded-lg text-lg text-white mt-5 mb-10 p-2" onClick={handleRecruitment}>
            This is the Way!
          </button>
        </div>
      </div>
    </div>
  )
}
