import React from "react";

export const DivisionCard = ({image, name, rank}) => {
  return (
    <div className="flex flex-col items-center mt-8">
        <div className="flex flex-col items-center p-4">
        <img src={image} className="w-full h-auto mb-2" alt={name} />
        <p className="font-medium text-center">{name}</p> 
        <p className="font-medium text-center">{rank}</p>
    </div>
    </div>
  );
};