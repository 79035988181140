import React from 'react';
import { DivisionCard } from './DivisionCard';
import { divisions } from "../data";
import { Link } from 'react-router-dom';
import felt from '../images/felt-texture.png';


export const Divisions = () => {

  return (
    <div className="w-full max-w-[1250px] mx-auto py-16">

<div className="flex flex-col space-y-4 xl:flex-row xl:space-y-0 items-center xl:justify-between">
    <p className="text-[1.4rem] font-bold text-center text-shadow-md mt-10">Explore our Divisions</p>
    <Link to="/divisions">
      <button className="bg-gradient-to-tr from-[#470000] to-black w-[7rem] py-3 text-sm rounded-lg">Learn more</button>
    </Link>
</div>

      <div className="flex flex-wrap justify-center xl:justify-between mt-16 ">
        {divisions.map((division, index) => (
          <div key={index} className="w-full md:w-1/2 xl:w-1/4 px-1 animate-fallIn" style={{ animationDelay: `${index * 200}ms` }}>
          <div 
  className="bg-black border-8 border-[#351E10] shadow-lg rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 flex flex-col items-center mb-20" 
  style={{ 
    backgroundImage: `url(${felt})`, 
    backgroundSize: 'contain' 
  }}>
  <DivisionCard image={division.image} name={division.name} />
</div>
          </div>
        ))}
      </div>

    </div>
  );
};