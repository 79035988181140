import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { links } from './data'; // make sure this path is correct


function App() {

  return (
    <Router>
      <main className="text-white">
        <Header />

        <Routes>
          {links.map((link, index) => (
            link.isExternal ? 
            <a key={index} href={link.path} target="_blank" rel="noreferrer">{link.name}</a> :
            <Route key={index} path={link.path} element={React.createElement(link.component)} />
          ))}
        </Routes>

        <Footer />
      </main>
    </Router>
  );
}

export default App;