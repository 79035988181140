import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MdMenu } from "react-icons/md";
import { images } from "../data";
import { handleDiscord } from "../utilities/methods";
import { Login } from "./Login";
import "../styles/header.css";

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="relative w-full bg-gradient-to-r from-gray-700 to-black shadow-custom-orange z-50">
        <div className="flex justify-between items-center w-full h-25 max-w-7xl mx-auto px-4 lg:px-8">
          <Link to="/">
            <img
              src={images.hwlogo}
              alt="logo"
              className="h-20 mt-3 rounded-full pb-4"
            />
          </Link>
          <ul className="hidden lg:flex items-center space-x-8 text-xl text-white">
            <li className="cursor-pointer hover:text-[#e87d0e]">
              <Link to="/">Home</Link>
            </li>
            <li className="cursor-pointer hover:text-[#e87d0e]">
              <Link to="/about">About</Link>
            </li>
            <li className="cursor-pointer hover:text-[#e87d0e]">
              <Link to="/divisions">Divisions</Link>
            </li>
            <li
              className="cursor-pointer hover:text-[#e87d0e]"
              onClick={handleDiscord}
            >
              Discord
            </li>
            <li className="cursor-pointer hover:text-[#e87d0e]">
              <Link to="/operations">Operations</Link>
            </li>
          </ul>

          {/* Mobile Navigation Button */}
          <div className="lg:hidden">
            <MdMenu size={28} className="text-white" onClick={toggleMenu} />
          </div>

          <button
            className="text-sm w-[6rem] py-1 rounded-full bg-gradient-to-tr from-[#63000A] to-[#470000]"
            onClick={() => setShowLogin(true)}
          >
            Log In
          </button>
          {showLogin && <Login onClose={() => setShowLogin(false)} />}
        </div>

        {/* Mobile Menu */}
        <div
          className={`absolute top-0 inset-x-0 p-2 transition transform origin-top-right ${
            isOpen ? "block" : "hidden"
          } bg-black bg-opacity-90 lg:hidden`}
        >
        <div className="mt-6">
                <nav className="grid gap-y-8">
                  <Link
                    to="/"
                    className="text-white hover:bg-gray-700 p-3 rounded-md text-base font-medium"
                    onClick={() => setIsOpen(false)}
                  >
                    Home
                  </Link>
                  <Link
                    to="/about"
                    className="text-white hover:bg-gray-700 p-3 rounded-md text-base font-medium"
                    onClick={() => setIsOpen(false)}
                  >
                    About
                  </Link>
                  <Link
                    to="/divisions"
                    className="text-white hover:bg-gray-700 p-3 rounded-md text-base font-medium"
                    onClick={() => setIsOpen(false)}
                  >
                    Divisions
                  </Link>
                  <Link
                    to="/operations"
                    className="text-white hover:bg-gray-700 p-3 rounded-md text-base font-medium"
                    onClick={() => setIsOpen(false)}
                  >
                    Operations
                  </Link>
                </nav>
              </div>
              </div>
      </div>
      {showLogin && <Login onClose={() => setShowLogin(false)} />}
    </>
  );
};



         