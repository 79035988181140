import React from "react";
import { MeetTheTeam } from "../components/MeetTheTeam";
import { leadership } from "../data";
import "../index.css";
import { images } from "../data";

export const About = () => {
  return (
<>
  <div>
    {/* Hero Section */}
    <div className="w-full xl:max-w-[auto] mx-auto px-4 text-center pt-20"> {/* Adjusted padding-top */}

    </div>

    {/* Hero Image with Container */}
    <div className="container mx-auto px-4 py-8" style={{ boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}> {/* Container with drop shadow */}
      <img src= {images.banner} alt="Hero" className="mx-auto" style={{ maxWidth: '100%', height: 'auto' }} />
    </div>


        
        <div className="container mx-auto px-4 py-8">
          <h2 className="text-3xl text-[#e87d0e] font-bold text-center mb-6 text-shadow-md">Our Mission</h2>
          <h6 className="text-center text-justify text-shadow-md">Unlike other Org’s we don’t put restrictions on our members like, what missions you can only do, having log so many hours daily or weekly into the game or make you pay into an Org fund. Our mission is to have fun playing this game with other like minded players and build a Clan that you can rely on to get you out of a jam in-game, run missions or just blow off steam. </h6>
          <br/>
          <p className="text-center text-justify mb-3">Welcome to Clan Mandalor, House Wolf

The Mandalorians were a clan-based culture composed of members from multiple species and bound by a common creed, language, and code. Known primarily as highly-effective mercenaries and bounty hunters, Mandalorians have at various points in galactic history played a major role as legendary warriors and conquerors.

Originating on the planet of Mandalore in the galaxy’s Outer Rim Territories, the Mandalorians’ way of life revolved around honor and war, being led by a sole ruler known as the Mand’alor, whose Protectors maintained a relative state of peace and unity between their people’s warrior clans. As a result of their warrior culture, the Mandalorians launched several wars of expansion by colonizing nearby worlds such as Krownest, Ordo, and Concord Dawn, leading the sector surrounding their homeworld to be known as Mandalorian Space.

Throughout their history, several prominent groups of Mandalorian warriors came into existence, such as Mandalorian crusaders, rally masters, and Mandalorian Neo-Crusaders, all of whom waged war. According to legends, many of these conflicts involved a Mand’alor who went by the name of Mandalore the Great.

However, although Mandalore was still under control of traditional Mandalorian warriors, much of the planet’s population had become part of a pacifist movement known as the New Mandalorians, led by the young Duchess Satine Kryze of House Kryze, with the traditionalist warriors being forced to use violence to hold on to power. Eventually, a civil war broke out between the pacifist New Mandalorian movement and the martial traditionalists, who wished to maintain their warrior heritage. Mandalore’s capital of Keldabe was destroyed during the Mandalorian Civil War, and many perished during the conflict, including Duke Adonai Kryze, Satine’s father. Satine herself had to remain under protection as the traditionalists sent bounty hunters after her. However, Mandalore’s traditionalist minority was eventually defeated during the war, being exiled to Mandalore’s moon of Concordia. While the Death Watch, a traditionalist group under Mand’alor Pre Vizsla, wished to retake their homeworld, the Old Mandalorians scattered across the galaxy as mercenaries.

Mandalorian Code of Honor is broken down into three facets: strength, honor, and death – all of which tie into the Mandalorians being a warrior culture centered on armor, weapons, and war. The Mandalorian Code of Honor says: “Strength is life, for the strong have the right to rule; Honor is life, for with no honor one may as well be dead; Death is life, one should die as they have lived.” The first aspect of strength means if Mandalorians meet a weaker person or people, they have the right to conquer them and absorb them into the Mandalorians’ way of life.

The Mandalorian Code of Honor doesn’t concern a person’s death in and of itself, but rather how the person dies. If a Mandalorian dies with strength and honor, then their death is an acceptable one that abides by the code.

The Mandalorians’ Resol’nare is broken down into six principles: raising children as Mandalorians, wearing the Mandalorian armor, mastering self-defense, devoting one’s self to the clan’s welfare, speaking the Mandalorian language, and answering the Mandalorian leader’s call to action.

“This is the way”</p>
        </div>

        {/* Meet the Team */}
        <div className="w-full xl:max-w-[auto] mx-auto mt-2">
          <h2 className="text-3xl font-bold text-center text-[#e87d0e] text-shadow-md">Meet the Leadership</h2>
            <div className=" rounded-lg text-center ">
              <MeetTheTeam leaders={leadership} />
            </div>
        </div>
      </div>
    </>
  );
};
