import banner from './images/banner.png';
import banner2 from './images/banner2.png';
import banner3 from './images/discord_banner3.png';
import comingSoon from './images/comingSoon.jpg';
import helmet from './images/helmet.png';
import hwlogo from './images/HWlogo.png';
import lccops from './images/LCCOPS.png';
import lead from './images/Leadership_Core.png';
import recruit from './images/rec-post.png';
import redacted from './images/redacted.png';
import specops from './images/SPECOPS.png';
import tacops from './images/TACOPS.png';
import cutter from './images/CutterWolf.jpg';
import wait from './images/waiting.jpg';
import {Home} from './pages/Home';
import {About} from './pages/About';
import {Operations} from './pages/Operations';
import { Dashboard } from './pages/Dashboard';
import { Divisions } from './pages/Divisions';


export const images = {
    banner,
    banner2,
    banner3,
    comingSoon,
    helmet,
    hwlogo,
    lccops,
    lead,
    recruit,
    redacted,
    specops,
    tacops,
    cutter,
    wait,
};

export const divisions = [
    { name: 'Leadership Core', image: images.lead, },
    { name: 'TACOPS', image: images.tacops, },
    { name: 'SPECOPS', image: images.specops, },
    { name: 'LOCCOPS', image: images.lccops, },
];

export const leadership = [
    { name: 'CutterWolf', rank: 'Clan Warlord', image: images.cutter},
    { name: 'Brokkr_ForgeMaster', rank: 'Hand of the Clan', image: images.lead},
    { name: 'Filius_ForgeMaster', rank: 'Armorer', image: images.lead},
    { name: 'REDACTED', rank: 'Advisor', image: images.lead},
];

export const links = [
    {
      path: '/',
      component: Home,
    },
    {
      path: '/about',
      component: About,
    },
    {
      path: '/operations',
      component: Operations,
    },
    {
      path: '/dashboard',
      component: Dashboard,
    },
    {
      path: '/divisions',
      component: Divisions,
    }
    // add more links as needed
  ];