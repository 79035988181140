import axios from "axios";

export const handleRecruitment = (e) => {
    e.preventDefault();
    window.location.href = "https://robertsspaceindustries.com/orgs/CUTTERWOLF";
}

export const handleDiscord = (e) => {
    e.preventDefault();
    window.location.href = "https://discord.gg/nb7hm7mFae";
}

export const handleHome = (e) => {
    e.preventDefault();
    window.location.href = "/";
}

// export const getYoutubeVideos = async () => {
//     const API_KEY = "AIzaSyAbf6LUatB2W7QHiR7dpxpsQP2OOnmGmQA";
//     const CHANNEL_ID = "UCp8NMJEqKGlJz5pj5jT_5XQ";
//     const cacheKey = 'youtubeVideos';
//     const cachedData = localStorage.getItem(cacheKey);

//     if (cachedData) {
//         const { expiry, videos } = JSON.parse(cachedData);
//         if (Date.now() < expiry) {
//             console.log('Returning cached videos');
//             return videos; 
//         }
//     }

//     try {
//         const response = await axios.get(`https://www.googleapis.com/youtube/v3/search`, {
//             params: {
//                 key: API_KEY,
//                 channelId: CHANNEL_ID,
//                 part: 'snippet',
//                 order: 'date',
//                 maxResults: 10,
//                 type: 'video',
//             }
//         });
//         const validVideos = response.data.items.filter(item => item.id.videoId);

//         const expiry = Date.now() + (1000 * 60 * 60);
//         localStorage.setItem(cacheKey, JSON.stringify({ videos: validVideos, expiry }));

//         return validVideos;
//     } catch (error) {
//         console.error('Error fetching YouTube videos', error);
//         return []; 
//     }
// }


