import React from 'react';
import {HeroComponent} from "../components/HeroComponent.js";
import {PhaseComponent} from '../components/PhaseComponent.js';
import {images} from '../data.js';

export const Operations = () => {
  // Placeholder data - replace with real data as needed
  const operationData = {
    title: "Operation Starfall - Coming Soon",
    date: "TBD",
    time: "TBD",
    participants: "TACOPS Division, LCCOPS Division, Leadership Core",
    heroImage: images.comingSoon, // Replace with actual image path
    phases: [
      {
        title: "Phase 1: Engagement and Air Support",
        date: "TBD",
        time: "TBD",
        description: "TACOPS will initiate the operation...",
        image: "path/to/phase1-image.jpg"
      },
      {
        title: "Phase 2: Cargo Recovery and Escort",
        description: "Following the successful neutralization of threats, LCCOPS will...",
        image: "path/to/phase2-image.jpg"
      },
    ]
  };

  return (
    <div className="w-full mx-auto px-4"> 
      <div className="flex flex-col items-center pt-2">
        <HeroComponent 
          title={operationData.title}
          date={operationData.date}
          time={operationData.time}
          participants={operationData.participants}
          heroImage={operationData.heroImage}
        />
        <div className="w-full max-w-4xl mx-auto pt-5">
          {operationData.phases.map((phase, index) => (
            <PhaseComponent 
              key={index}
              phaseTitle={phase.title}
              description={phase.description}
              // image={phase.image} 
            />
          ))} 
        </div>
      </div>
    </div>
  );
};