import React from 'react';
import { leadership } from '../data';
import { DivisionCard } from './DivisionCard';

export const MeetTheTeam = () => {
  

  return (
    <div className="w-full max-w-[1250px] mx-auto py-16">

<div className="flex flex-col space-y-4 xl:flex-row xl:space-y-0 items-center xl:justify-between">
</div>

      <div className="flex flex-wrap justify-center xl:justify-between mt-16 ">
        {leadership.map((leader, index) => (
          <div key={index} className="w-full md:w-1/2 xl:w-1/4 px-1">
            <div className="bg-black border-8 border-[#351E10] shadow-lg rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 flex flex-col items-center animate-fallIn" style={{ background: 'black url(path/to/felt-texture.png)', animationDelay: `${index * 200}ms` }} >
              <DivisionCard image={leader.image} name={leader.name} rank={leader.rank} />
            </div>
          </div>
        ))}
      </div>

    </div>
  );
};