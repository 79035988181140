import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../context/GlobalState.js";
import AuthService from "../services/auth.service";
import { images } from "../data";
import request from "../services/api.request";
import "../index.css";

import {jwtDecode} from 'jwt-decode';


export const Login = ({ onClose }) => {
  let navigate = useNavigate();
  const [ dispatch] = useGlobalState();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const resp = await AuthService.login(username, password);
      const data = jwtDecode(resp.access);
      const userData = await getProfile(data.user);
      await dispatch({
        currentUserToken: data.access,
        currentUser: userData,
      });
    } catch (error) {
      // Handle login error (e.g., incorrect credentials, network issue)
    }
    // navigate("/dashboard");
    onClose();
  };
  
  
  const getProfile = async (user) => {
    let resp = await request({
      url: `user/${user.id}`,
      method: 'GET',
    })
    return resp.data
  }


  return (
    <div className="fixed inset-5 bg-transparent bg-opacity-50 flex items-center justify-center z-50">
    <div className="absolute inset-0 bg-black opacity-50" onClick={onClose}></div>
    <div className="relative z-10 glass-effect rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
       <button
          className="absolute top-0 left-0 mt-1 ml-2 text-red-500 hover:text-red-900 text-1xl font-bold"
          onClick={onClose}
        >
          &#10005; 
        </button>
        <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <h2 className="text-center text-3xl font-extrabold text-gray-900">
            House Wolf Account
          </h2>
          <div className="flex justify-center mt-4">
            <img
              src={images.banner3}
              alt="House Wolf Banner"
              className="w-20 h-20 object-cover rounded-full"
            />
          </div>
          <div className="mt-8 text-black">
            <form action="#" method="POST" className="space-y-6">
              <input
                type="text"
                name="username"
                id="username"
                autoComplete="username"
                placeholder="Username"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                onChange={(e) => setUsername(e.target.value)}
              />
              <input
                type="password"
                name="password"
                id="password"
                autoComplete="current-password"
                placeholder="Password"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                onChange={(e) => setPassword(e.target.value)}
              />
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-tr from-[#470000] to-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={handleLogin}
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="your-image-url.jpg"
            alt="Login"
          />
        </div>
      </div>
    </div>
  );
};
