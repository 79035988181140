
import React from "react";
import { handleRecruitment } from "../utilities/methods";
import space from "../images/space.mp4";
import "../index.css";
// import {VideoSlider} from "./VideoSlider";

export const Hero = () => {
  return (
    <div>
      {/* Video Background */}
      <div className="relative pt-40">
      <div className="relative w-full h-[500px] rounded-xl overflow-hidden">
        <video 
          autoPlay 
          loop 
          muted 
          style={{ filter: 'brightness(40%) contrast(90%)', pointerEvents: 'none' }}

          className="absolute top-0 left-0 w-full h-full object-cover z-[-50] mt-30"
        >
          <source src={space} type="video/mp4" />
        </video>
        </div>
        {/* Centered Text and Button */}
        <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center z-[-50]">
          <h2 className="text-5xl font-bold text-[#e87d0e] text-shadow-md text-center mt-40 mb-10">
            Travel the 'Verse with us!
          </h2>

          <button
  type="button"
  className="animate-pulseGlow bg-gradient-to-tr from-[#470000] to-black text-sm w-[10rem] py-2 rounded-full text-white mt-8" // increased mt from mt-4 to mt-8
  onClick={handleRecruitment}
>
  Join Today!
</button>
        </div>
      </div>
      {/* <div className="flex justify-center mt-5"> */}
        {/* <div className="g-ytsubscribe youtube-subscribe" data-channelid="UCp8NMJEqKGlJz5pj5jT_5XQ" data-layout="full" data-count="default"></div> */}
      {/* </div> */}
      {/* <div className="text-center mt-8"> */}
        {/* <VideoSlider /> */}
      {/* </div> */}
    </div>
  );
};

