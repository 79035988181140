import React from "react";
import { images } from "../data";
import "../index.css";

export const CTA = () => {
  return (
    <div className="w-full py-[1rem] items-center justify-center">
      <h1 className="text-[2rem] font-bold text-center text-shadow-md">
        Join the Elite Community of House Wolf!
      </h1>

      <div className="division-block">
        <h3 className="division-heading text-[#e87d0e]">
          Tactical Air Control Operations Division (TACOPS)
        </h3>
        <ul className="division-description">
          <li className="text-shadow-default">
            Responsible for the coordination of air support for ground
            operations.
          </li>
          <li className="text-shadow-default">
            The eyes and ears of the battlefield, and the voice of the skies.
          </li>
        </ul>
      </div>

      <div className="division-block">
        <h3 className="division-heading text-[#e87d0e]">
          Special Operations Division (SPECOPS)
        </h3>
        <ul className="division-description">
          <li className="text-shadow-default">Execution of high-risk, high-reward missions.</li>
          <li className="text-shadow-default">The tip of the spear, and the first to strike.</li>
        </ul>
      </div>

      <div className="division-block">
        <h3 className="division-heading text-[#e87d0e]">
          Logistics and Command Operations Division (LOCCOPS)
        </h3>
        <ul className="division-description">
          <li className="text-shadow-default">Coordination of logistics and command operations.</li>
          <li className="text-shadow-default">The backbone of the operation, and the heart of the team.</li>
        </ul>

        <p className="text-sm text-center text-gray-100 mt-6 text-shadow-default mb-20">
          The Leadership Core is responsible for the coordination of all
          operations. They oversee all divisions and only step in if the
          situation requires it.
        </p>
      </div>
      <div className="flex w-full mt-[2rem]">
        <img src={images.banner2} alt="community" className="shadow-image block"/>
      </div>
    </div>
  );
};