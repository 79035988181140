import React from 'react'
import { images } from '../data.js';

export const Divisions = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen px-4">
            <div className="text-center mt-20">
                <h1 className="text-4xl font-bold text-[#e87d0e] mb-4 text-shadow-md">Coming Soon</h1>
                <p className="text-lg text-white text-shadow-md">More about our Divisions coming soon!</p>
            </div>
            <div className="w-full max-w-4xl h-3/4 pt-8">
                <img src={images.wait} alt="Logo" className="w-full h-full object-cover rounded-lg shadow-lg" /> {/* Added rounded-lg and shadow-lg */}
            </div>
        </div>
    );
};

