export const HeroComponent = ({ title, date, time, participants, heroImage }) => (
  <div className="bg-cover bg-center text-white text-center py-20 " >
    <div className="rounded bg-black bg-opacity-50 p-10">
      <h1 className="text-[#e87d0e] text-4xl font-extrabold mb-4">{title}</h1>
      <p className="text-lg mb-2">Date: {date}</p>
      <p className="text-lg mb-2">Time: {time}</p>
      <p className="text-lg">Division: {participants}</p>
    </div>
    <div className="flex justify-center pt-5">
      <img src={heroImage} alt="Operation Starfall" className="max-w-full h-auto rounded-lg " />
    </div>
  </div>
);