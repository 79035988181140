import React from 'react';
import { Hero } from '../components/Hero';
import { CTA } from '../components/CTA';
import { Divisions } from '../components/Divisions';



export const Home = () => {
    return (
        <div >
          <Hero />
          <Divisions />
          <CTA />
        </div>
      );
    }
